import { FC } from "react"
// @ts-ignore
import InlineSVG from "svg-inline-react"
import { MenuType } from "../../../redux/reducers/menuRudecer"

const MykritlabMenuItem: FC<{ item: MenuType }> = ({ item }) => {
  const hasAdditionalMenu = item.items?.length
  if (hasAdditionalMenu) {
    return (
      <div className="menuSectionList__item withList">
        <div className="menuSectionList__itemContent">
          <div className="menuList_title">{item.name}</div>
          <div className="menuList_list">
            {item.items?.map((item, i: number) => {
              return (
                <a href={item?.href} className="menuList_link" target="_blank" key={i}>
                  {item?.name}
                </a>
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="menuSectionList__item">
        <a href={item.href} rel="noopener noreferrer" target="_blank" className="menuSectionList__itemWrap">
          <div className="menuSectionList__itemContent">
            <div className="menuSectionList__itemIcon">
              <InlineSVG src={item.image} />
            </div>
            <div className="menuSectionList__itemCaption">{item.name}</div>
          </div>
        </a>
      </div>
    )
  }
}

export default MykritlabMenuItem
