import { InferActionsType } from "../store"
import { myKritLabMenuAPI } from "../../api/api"
import { checkHttpError } from "../../helpers/checkHttpError"

const SET_MAIN_MENU = "SET_MAIN_MENU"

export type MenuType = {
  [x: string]: any
  href: string
  name: string
  image: string
  react: boolean
  id: number
  items?: {
    name: string
    href: string
  }[]
}
export type InitialDataStateType = {
  menu: MenuType[]
}
export const initialState: InitialDataStateType = {
  menu: []
}
const menuReducer = (state = initialState, action: ActionsType) => {
  switch (action.type) {
    case SET_MAIN_MENU:
      return {
        ...state,
        menu: action.data
      }
    default:
      return state
  }
}
type ActionsType = InferActionsType<typeof actions>
export const actions = {
  setMainMenu: (data: any) => ({ type: SET_MAIN_MENU, data } as const)
}

export const getMenuThunk = () => async (dispatch: any) => {
  const { menu, status } = await myKritLabMenuAPI.getMenu()
  await checkHttpError(status, dispatch)
  dispatch(actions.setMainMenu(menu))
}

export default menuReducer
